<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="820px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="handleClose">
		</span>
        <div class="top-right-box">
            <label class="label">经销商名称</label>
            <el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入经销商名称"></el-input>
            <img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
            <el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
        </div>
		<div class="data-list-box">
			<el-table
				:data="dealerList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					width="50"
					label="NO.">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="code"
					width="90"
					show-overflow-tooltip
					label="经销商编码">
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="经销商名称">
				</el-table-column>
				<el-table-column
					prop="contractLevelName"
					show-overflow-tooltip
					width="100"
					label="签约级别">
				</el-table-column>
				<el-table-column
					prop="contractTypeName"
					show-overflow-tooltip
					width="150"
					label="签约类型">
				</el-table-column>
				<el-table-column
					label="状态"
					align="center"
					width="50">
					<template slot-scope="scope">
						<span v-if="scope.row.active == 1">Y</span>
						<span v-if="scope.row.active == 0" style="color:red;">N</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="90">
					<template slot-scope="scope">
                        <button class="btn-blue" v-if="scope.row.sureFlag == 0" @click="handleBind(scope.row)">绑定</button>
                        <button class="btn-red" v-if="scope.row.sureFlag == 1" @click="handleBind(scope.row)">解绑</button>
						<button class="btn-red" v-if="scope.row.sureFlag == 0" @click="handleDelTemp(scope.row)">清除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
import Pagination from '@/components/Pagination'
export default {
    components: {
		Pagination
	},
	data() {
		return {
			title: '关联网络店',
			visible: false,
            searchKey: '',
			pageNo: 1,
			pageSize: 10,
			total: 0,
			active: '',
			area: '',
			contractLevel: '',
			areaList: [],
			provice: '',
			proviceList: [],
			dealerList: [],
			dataList: [],
			deviceType: '',
			deviceTypeList: [],
			errorList: [],
			contractLevelList: [],
            id: ''
		}
	},
	methods: {
		init(dt) {
			this.visible = true;
			this.$nextTick(() => {
                this.pageNo = 1;
                this.searchKey = '';
                this.id = dt.id;
                this.getDealerList();
			})
		},
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1;
			this.searchKey = '';
			this.area = '';
			this.active = '';
			this.provice = '';
			this.getDealerList();
		},
		// 获取经销商数据
		getDealerList() {
			let that = this;
			that.$request.post(
				"getDealerList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					rangeId: that.area,
					active: that.active,
					areaId: that.provice,
					contractLevel: that.contractLevel,
                    id: that.id
				},
				function (r) {
					if (r.code == "0") {
						that.dealerList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDealerList();
		},
		handleSizeChange(val) {
			this.getDealerList();
		},
		handleCurrentChange(val) {
			this.getDealerList();
		},
        // 解绑/绑定
        handleBind(dt) {
            let sureMsg = dt.sureFlag == '0' ? '绑定' : '解绑';
            this.$confirm(`是否${sureMsg}当前经销商?`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysAgentSure',
					true,
					{
						id: dt.id,
						parentId: that.id,
                        sureFlag: dt.sureFlag ? '0' : '1'
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success(`${sureMsg}成功`);
                            that.getDealerList();
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
        },
		// 关闭
		handleClose() {
			this.$emit('getDealerList');
			this.visible = false
		},
		// 清除
		handleDelTemp(dt) {
			this.$confirm(`是否清除当前记录?`, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysAgentSureDelTemp',
					true,
					{
						id: dt.id,
						parentId: that.id,
						sureFlag: dt.sureFlag ? '0' : '1'
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success(`操作成功`);
							that.getDealerList();
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		}
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
.top-right-box{
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 40px;
    .label{
        font-size: 15px;
        margin-right: 5px;
    }
    .search-input,
    .search-select{
        width: 138px;
        margin: 0 10px;
    }
    .search-btn{
        width: 32px;
        vertical-align: middle;
        cursor: pointer;
    }
    .refresh-btn{
        width: 32px;
        height: 32px;
        border: none;
        color: #117FFC;
        padding: 0;
        background-color: rgba(17, 127, 252, 0.2);
        font-weight: bold;
        font-size: 22px;
        vertical-align: middle;
        margin-left: 5px;
    }
}
.btn-blue,
.detail-btn{
    font-size: 12px;
    font-weight: bold;
    color: #1081FD;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    background-color: #fff;
    margin-right: 10px;
}
.btn-red{
    font-size: 12px;
    font-weight: bold;
    color: #E9515E;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    background-color: #fff;
    margin-right: 10px;
}
</style>
